const EditorConfig = () => {
  return (
    <div>
      <h2>编辑器设置</h2>
      <div>编辑器默认编辑模式</div>
      <div>markdown</div>
      <div>block</div>
    </div>
  );
};
export default EditorConfig;
