export default {
  en: {
    translation: {
      customizeYourAI: "Customize Your AI",
      createDialogFromAI: "Create Dialog from AI",
      createRobot: "Create Robot",
      createDialog: "Create Dialog",
      playAudio: "Play Audio",
      copyContent: "Copy Content",
      saveContent: "Save Content",
      deleteMessage: "Delete Message",
      saveSuccess: "Save successful",
      clickHere: "Click here",
      viewDetails: "to view details.",
      saveFailed: "Save failed",
      copySuccess: "Copy successful",
      copyFailed: "Copy failed",
      useServerProxy: "Use Transit Service",
      resend: "Resend",
      clearConversation: "Clear Conversation",
      newchat: "New chat",
      // --- Added based on DeleteContentButton ---
      delete: "Delete",
      deleteFailed: "Delete failed",
      deleteSuccess: "Delete successful",
      deleteContentTitle: 'Delete "{{title}}"',
      deleteContentConfirmation:
        "Are you sure you want to delete this content? This action cannot be undone.",
      cancel: "Cancel",
      // --- End Added ---
    },
  },
  "zh-CN": {
    translation: {
      customizeYourAI: "自定义您的AI",
      createDialogFromAI: "从AI创建对话",
      createRobot: "创建机器人",
      createDialog: "创建对话",
      playAudio: "播放音频",
      copyContent: "复制内容",
      saveContent: "保存内容",
      deleteMessage: "删除消息",
      saveSuccess: "保存成功",
      clickHere: "点击此处",
      viewDetails: "查看详情。",
      saveFailed: "保存失败",
      copySuccess: "复制成功",
      copyFailed: "复制失败",
      useServerProxy: "使用中转服务",
      resend: "重新发送",
      clearConversation: "清除对话",
      newchat: "新对话",
      // --- Added based on DeleteContentButton ---
      delete: "删除",
      deleteFailed: "删除失败",
      deleteSuccess: "删除成功",
      deleteContentTitle: '删除 "{{title}}"',
      deleteContentConfirmation: "您确定要删除此内容吗？此操作无法撤销。",
      cancel: "取消",
      // --- End Added ---
    },
  },
  "zh-Hant": {
    translation: {
      customizeYourAI: "自定義您的AI",
      createDialogFromAI: "從AI創建對話",
      createRobot: "創建機器人",
      createDialog: "創建對話",
      playAudio: "播放音頻",
      copyContent: "複製內容",
      saveContent: "保存內容",
      deleteMessage: "刪除消息",
      saveSuccess: "保存成功",
      clickHere: "點擊此處",
      viewDetails: "查看詳情。",
      saveFailed: "保存失敗",
      copySuccess: "複製成功",
      copyFailed: "複製失敗",
      useServerProxy: "使用中轉服務",
      resend: "重新發送",
      clearConversation: "清除對話",
      newchat: "新對話",
      // --- Added based on DeleteContentButton ---
      delete: "刪除",
      deleteFailed: "刪除失敗",
      deleteSuccess: "刪除成功",
      deleteContentTitle: '刪除 "{{title}}"',
      deleteContentConfirmation: "您確定要刪除此內容嗎？此操作無法撤銷。",
      cancel: "取消",
      // --- End Added ---
    },
  },
  ja: {
    translation: {
      customizeYourAI: "AIをカスタマイズ",
      createDialogFromAI: "AIから対話を作成",
      createRobot: "ロボットを作成",
      createDialog: "対話を作成",
      playAudio: "音声を再生",
      copyContent: "内容をコピー",
      saveContent: "内容を保存",
      deleteMessage: "メッセージを削除",
      saveSuccess: "保存に成功しました",
      clickHere: "ここをクリック",
      viewDetails: "して詳細を表示。",
      saveFailed: "保存に失敗しました",
      copySuccess: "コピーに成功しました",
      copyFailed: "コピーに失敗しました",
      useServerProxy: "中継サービスを使用する ",
      resend: "再送信",
      clearConversation: "会話をクリア",
      newchat: "新しいチャット",
      // --- Added based on DeleteContentButton ---
      delete: "削除",
      deleteFailed: "削除に失敗しました",
      deleteSuccess: "削除に成功しました",
      deleteContentTitle: "「{{title}}」を削除します",
      deleteContentConfirmation:
        "このコンテンツを削除してもよろしいですか？この操作は元に戻せません。",
      cancel: "キャンセル",
      // --- End Added ---
    },
  },
};
